export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Single-tool.png',
    title: 'accessControl.section3.card1.title',
    content: 'accessControl.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Ease-of-use.png',
    title: 'accessControl.section3.card2.title',
    content: 'accessControl.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Highly-integrated.png',
    title: 'accessControl.section3.card3.title',
    content: 'accessControl.section3.card3.content'
  }
]
